

export const clamp = window.clamp = ( value, min, max ) => Math.min( max, Math.max( min, value ) );

export const factorInRange = window.factorInRange = ( value, min, max ) => min === max ? value : clamp( ( value - min ) / ( max - min ), 0, 1 );

export const factorIn = window.factorIn = ( value, start = 0, end = 1 ) => factorInRange( value, start, end );

export const factorOut = window.factorOut = ( value, start = 0, end = 1 ) => factorInRange( value, end, start );

export const factorInOut = window.factorInOut = ( value, start = 0, mid = 0.5, end = 1 ) => {
    return value > mid ? factorOut( value, mid, end ) : factorIn( value, start, mid );
}

export const mix = window.mix = ( valueA, valueB, factor ) => valueA + ( valueB - valueA ) * factor;

export const easing = {
    // no easing, no acceleration
    linear: (t) => t,
    // accelerating from zero velocity
    easeInQuad: (t) => t * t,
    // decelerating to zero velocity
    easeOutQuad: (t) => t * (2 - t),
    // acceleration until halfway, then deceleration
    easeInOutQuad: (t) => (t < 0.5 ? 2 * t * t : -1 + (4 - 2 * t) * t),
    // accelerating from zero velocity
    easeInCubic: (t) => t * t * t,
    // decelerating to zero velocity
    easeOutCubic: (t) => --t * t * t + 1,
    // acceleration until halfway, then deceleration
    easeInOutCubic: (t) => (t < 0.5 ? 4 * t * t * t : (t - 1) * (2 * t - 2) * (2 * t - 2) + 1),
    // accelerating from zero velocity
    easeInQuart: (t) => t * t * t * t,
    // decelerating to zero velocity
    easeOutQuart: (t) => 1 - --t * t * t * t,
    // acceleration until halfway, then deceleration
    easeInOutQuart: (t) => (t < 0.5 ? 8 * t * t * t * t : 1 - 8 * --t * t * t * t),
    // accelerating from zero velocity
    easeInQuint: (t) => t * t * t * t * t,
    // decelerating to zero velocity
    easeOutQuint: (t) => 1 + --t * t * t * t * t,
    // acceleration until halfway, then deceleration
    easeInOutQuint: (t) => (t < 0.5 ? 16 * t * t * t * t * t : 1 + 16 * --t * t * t * t * t),
}